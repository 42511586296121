var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-groups"},[_c('a-modal',{attrs:{"title":_vm.$t('trigger_action_extra_setting_modal_title'),"after-close":_vm.afterModalClose,"ok-text":_vm.$t('save_button')},on:{"ok":_vm.setTriggerActionModel},model:{value:(_vm.isActionExtraSettingModalOpen),callback:function ($$v) {_vm.isActionExtraSettingModalOpen=$$v},expression:"isActionExtraSettingModalOpen"}},[(_vm.currentActionInfo.action !== null)?_c('action-chance',{staticClass:"mt-0 mb-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.currentActionInfo.action.value.structure),callback:function ($$v) {_vm.$set(_vm.currentActionInfo.action.value, "structure", $$v)},expression:"currentActionInfo.action.value.structure"}}):_vm._e(),(_vm.currentActionForUserAction)?_c('text-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.currentActionInfo.action.value.structure,
          'key': 'targetSource',
          'validation': 'integer',
          'tariffTags': _vm.brandTag
        }
      }}}):_vm._e()],1),_c('list',{attrs:{"config":{
      'data': _vm.model,
      'keyFn': function (item) { return item.guid; },
      'direction': _vm.ListDirection.Vertical
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('a-card',{staticClass:"trigger-group"},[_c('div',{staticClass:"flex gap-2 flex-col xs:flex-row xs:items-center xs:justify-between",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('action_group_index', [index+1]))+" "),_c('div',{staticClass:"flex items-center gap-3"},[_c('block-moving-controls',{attrs:{"vertical":true,"position":index,"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('a-button',{staticClass:"text-danger hover:opacity-75",attrs:{"icon":"delete","type":"link"},on:{"click":function($event){_vm.model = _vm.model.filter(function (filteredItem, filteredIndex) { return filteredIndex !== index; })}}})],1)]),[_c('nested-content',{staticClass:"mt-0"},[(_vm.showFromTo)?_c('div',{ref:item.guid},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.triggerInputSetupFn,
                'args': {
                  'model': item.value,
                  'key': 'from',
                  'prefix': 'trigger_action_group_',
                  'disabled': _vm.disabled,
                  'max': item.value.to,
                  'min': 0
                }
              }}}),_c('number-input',{attrs:{"setup":{
                'func': _vm.triggerInputSetupFn,
                'args': {
                  'model': item.value,
                  'key': 'to',
                  'prefix': 'trigger_action_group_',
                  'disabled': _vm.disabled,
                  'min': item.value.from,
                  'max': 100,
                }
              }}})],1):_vm._e(),_c('number-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
              'func': _vm.triggerInputSetupFn,
              'args': {
                'model': item.value,
                'key': 'chance',
                'prefix': 'trigger_action_group_',
                'disabled': _vm.disabled,
                'max': 100,
                'formatter': function (value) { return (value + "%"); },
                'parser': function (value) { return value.replace('%', ''); },
              }
            }}})],1),_c('list',{staticClass:"mt-5",attrs:{"config":{
            'data': item.value.actions,
            'keyFn': function (item) { return item.guid; },
            'direction': _vm.ListDirection.Vertical,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var index = ref.index;
return [_c('a-card',{staticClass:"trigger-card"},[_c('div',{staticClass:"flex gap-2 flex-col xs:flex-row xs:items-center xs:justify-between",attrs:{"slot":"title"},slot:"title"},[_c('div',[_c('a-button',{staticClass:"mr-1",class:{'active': item.value.actions[index].value.structure.chance !== 100},attrs:{"icon":"setting","title":_vm.$t('trigger_action_extra_setting_button_title')},on:{"click":function($event){return _vm.prepareCurrentActionExtraSettings(item.guid, item.value.actions[index])}}}),_vm._v(" "+_vm._s(_vm.$t('action_index', [index+1]))+" ")],1),_c('div',{staticClass:"flex items-center gap-3"},[_c('block-moving-controls',{attrs:{"vertical":true,"position":index},model:{value:(item.value.actions),callback:function ($$v) {_vm.$set(item.value, "actions", $$v)},expression:"item.value.actions"}}),_c('a-button',{staticClass:"text-danger hover:opacity-75",attrs:{"type":"link","icon":"delete"},on:{"click":function () {
                    item.value.actions.splice(index, 1)
                    _vm.$emit('input', _vm.model)
                  }}})],1)]),[_c('config-field',{staticClass:"text-lg",attrs:{"title":_vm.$t(item.value.actions[index].value.titleKey),"help-message":_vm.$te(((item.value.actions[index].value.titleKey) + "_help_message")) ? _vm.$t(((item.value.actions[index].value.titleKey) + "_help_message")) : null}}),_c(item.value.actions[index].value.setupView,{tag:"component",attrs:{"disabled":_vm.disabled,"has-form":_vm.hasForm},model:{value:(item.value.actions[index].value.structure),callback:function ($$v) {_vm.$set(item.value.actions[index].value, "structure", $$v)},expression:"item.value.actions[index].value.structure"}})]],2),(index !== item.value.actions.length - 1)?_c('div',{staticClass:"text-primary text-center font-bold uppercase mt-5"},[_vm._v(" "+_vm._s(_vm.$t('trigger_group_and'))+" ")]):_vm._e()]}}],null,true)}),(!_vm.disabled)?_c('create-action',{staticClass:"mt-6",attrs:{"actions":_vm.filteredActions},on:{"select-action":function (key) { return _vm.createAction(item, key); }}}):_vm._e()]],2),(index !== _vm.model.length - 1)?_c('div',{staticClass:"text-primary text-center font-bold uppercase mt-5"},[(_vm.actionExecuteType === 'All')?[_vm._v(" "+_vm._s(_vm.$t('trigger_group_and'))+" ")]:_vm._e(),(_vm.actionExecuteType === 'Random')?[_vm._v(" "+_vm._s(_vm.$t('trigger_group_or'))+" ")]:_vm._e()],2):_vm._e()]}}])}),(!_vm.disabled)?_c('div',{staticClass:"text-center mt-5"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.addActionGroup(_vm.model)}}},[_vm._v(" "+_vm._s(_vm.$t('trigger_setup_button_add_action_group'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }