var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('accordion',{staticClass:"extra-settings",attrs:{"open-title":"accordion_open_extra_settings","close-title":"accordion_close_extra_settings"},model:{value:(_vm.isExtraSettingsOpen),callback:function ($$v) {_vm.isExtraSettingsOpen=$$v},expression:"isExtraSettingsOpen"}},[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('triggers_advanced_settings_help_message')}}),_c('div',{staticClass:"flex flex-col"},[_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'actionExecuteType',
          'prefix': 'trigger_setup_',
          'options': _vm.getActionExecuteTypeOptions,
          'disabled': _vm.disabled,
          'clearable': false
        }
      }}}),_c('div',{directives:[{name:"hide-once",rawName:"v-hide-once",value:(!_vm.triggerData.from && !_vm.triggerData.to),expression:"!triggerData.from && !triggerData.to"}]},[_c('div',{staticClass:"flex flex-col w-full md:flex-row md:gap-5"},[_c('time-picker-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.triggerData,
              'key': 'from',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
              'time24hr': true
            }
          }}}),_c('time-picker-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.triggerData,
              'key': 'to',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
              'time24hr': true
            }
          }}})],1),_c('timezone-select')],1),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'delay',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'chance',
          'prefix': 'trigger_setup_',
          'max': 100,
          'disabled': _vm.disabled,
          'formatter': function (value) { return (value + "%"); },
          'parser': function (value) { return value.replace('%', ''); },
        }
      }}}),_c('switch-input',{directives:[{name:"hide-once",rawName:"v-hide-once",value:(!_vm.triggerData.transparent),expression:"!triggerData.transparent"}],attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'transparent',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'processAlbumsLikeFirstPhoto',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'processEditedMessages',
          'prefix': 'trigger_setup_',
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.triggerData,
          'key': 'disableLog',
          'prefix': 'trigger_setup_',
          'tariffTags': _vm.checkLicense ? _vm.advancedLicenseTag : null,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }