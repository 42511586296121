import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SendMessageToTargetActionSetup
  from "@/components/TriggerSetup/actions/SendMessageToTargetAction/SendMessageToTargetActionSetup.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { BRAND_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': SendMessageToTargetActionSetup,
  'structure': {
    'type': ActionsType.SendMessageToTargetAction,
    'formButtonAction': "",
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'target'          : '',
    'chance'          : 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SendMessageToTargetAction, 'trigger_action_'),
  limited: () => store.getters.getHaveLicense && store.getters.getHaveBrand && store.getters.isChatLicenseExists ? null : BRAND_TAG

}

export default action
