import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SendMessageToCalculatedTargetActionView from "@/components/TriggerSetup/actions/SendMessageToCalculatedTargetAction/SendMessageToCalculatedTargetActionView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': SendMessageToCalculatedTargetActionView,
  'structure': {
    'type'      : ActionsType.SendMessageToCalculatedTargetAction,
    'targetType': "Target",
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'failedText': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'chance'    : 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SendMessageToCalculatedTargetAction, 'trigger_action_'),
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default action
