import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SendMessageActionSetupView
  from "@/components/TriggerSetup/actions/SendMessageAction/SendMessageActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "../../logic/helpers";

const action: EntityData = {
  'setupView': SendMessageActionSetupView,
  'structure': {
    'type': ActionsType.SendMessageAction,
    'formButtonAction': "",
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'asReply': 'Reply',
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SendMessageAction, 'trigger_action_'),
}

export default action
