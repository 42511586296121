import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SendPrivateMessageActionSetupView
  from "@/components/TriggerSetup/actions/SendPrivateMessageAction/SendPrivateMessageActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': SendPrivateMessageActionSetupView,
  'structure': {
    'type': ActionsType.SendPrivateMessageAction,
    'formButtonAction': "",
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'users' : [],
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SendPrivateMessageAction, 'trigger_action_'),
  limited: () => {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
  }
}

export default action
