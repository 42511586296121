import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SendFormResultToTargetSetupView
  from "@/components/TriggerSetup/actions/SendFormResultToTarget/SendFormResultToTargetSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { BRAND_TAG } from "@/includes/constants";
import store from "@/store/store";

const action: EntityData = {
  'setupView': SendFormResultToTargetSetupView,
  'structure': {
    'type': ActionsType.SendFormResultToTarget,
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'target': null,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SendFormResultToTarget, 'trigger_action_'),
  limited: () => store.getters.getHaveLicense && store.getters.getHaveBrand && store.getters.isChatLicenseExists ? null : BRAND_TAG
}

export default action
