import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import ForwardReplyMessageToTargetActionSetupView
  from "@/components/TriggerSetup/actions/ForwardReplyMessageToTargetAction/ForwardReplyMessageToTargetActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': ForwardReplyMessageToTargetActionSetupView,
  'structure': {
    'type': ActionsType.ForwardReplyMessageToTargetAction,
    'target': null,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.ForwardReplyMessageToTargetAction, 'trigger_action_'),
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default action
