


















import {
  getConditionZoneIcon,
  getConditionZoneMiniHelpMessage,
  getConditionZoneTitle
} from "@/components/TriggerSetup/components/ConditionZone/helpers";
import { ConditionZone } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import EntitySelect from "@/components/TriggerSetup/components/EntitySelect/EntitySelect.vue";
import { conditionsGroupsTypes, newConditionWrappers } from "@/components/TriggerSetup/conditions"
import { ConditionIcons } from "@/components/TriggerSetup/conditions/icons"

import { Entity, Item } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types';
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types';

import { Component, Emit, Prop } from 'vue-property-decorator'
import { snakeCase } from "lodash";
import { Guid } from "guid-typescript";
import Vue from "vue";

@Component({
  'components': {
    EntitySelect
  },
  'data': () => {
    return {
      conditionsGroupsTypes,
      ConditionIcons
    }
  }
})
export default class CreateCondition extends Vue {
  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Boolean, required: false, default: true }) checkLicense!: boolean

  @Emit()
  selectCondition(key: Item["key"]): { key: Item["key"], zone: ConditionZone | null } {
    return { key, zone: this.currentTab }
  }

  filteredConditions: Array<Entity> = []

  currentTab: ConditionZone = ConditionZone.Message;

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: getConditionZoneTitle(ConditionZone.Common).toString(),
        value: ConditionZone.Common,
        icon: getConditionZoneIcon(ConditionZone.Common),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.Common).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.Message),
        value: ConditionZone.Message,
        icon: getConditionZoneIcon(ConditionZone.Message),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.Message).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.ReplyMessage),
        value: ConditionZone.ReplyMessage,
        icon: getConditionZoneIcon(ConditionZone.ReplyMessage),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.ReplyMessage).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.RequestMessage),
        value: ConditionZone.RequestMessage,
        icon: getConditionZoneIcon(ConditionZone.RequestMessage),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.RequestMessage).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.RequestActor),
        value: ConditionZone.RequestActor,
        icon: getConditionZoneIcon(ConditionZone.RequestActor),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.RequestActor).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.ReactionMessage),
        value: ConditionZone.ReactionMessage,
        icon: getConditionZoneIcon(ConditionZone.ReactionMessage),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.ReactionMessage).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.ReactionActor),
        value: ConditionZone.ReactionActor,
        icon: getConditionZoneIcon(ConditionZone.ReactionActor),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.ReactionActor).toString(),
      }
    ]
  }

  afterModalClose() {
    this.currentTab = ConditionZone.Message
  }

  preparedConditions(tab: string | null): Array<Entity> {
    return Object.entries(conditionsGroupsTypes).reduce((acc: Array<Entity>, [ group, item ]) => {

      const isWrapper = (type: string) => newConditionWrappers.some(w => w.key === type)

      const filtered = tab === null ? item : item.filter(i => {
        if (isWrapper(i.key)) {
          return i.extraProps?.conditions?.some((c: EntityData) => c?.extraProps?.possibleConditionZone.includes(tab))
        } else {
          return i?.extraProps?.possibleConditionZone.includes(tab)
        }
      })

      if (filtered.length || !tab) {
        acc.push({
          group: {
            guid: Guid.create().toString(),
            titleKey: `trigger_group_${ snakeCase(group) }`,
            groupItems: filtered.map(c => {
              let limited = null

              if (this.checkLicense) {
                if (typeof c.limited === 'function') {
                  limited = c.limited()
                }
              }

              return {
                key: c.key,
                title: this.$t(c.titleKey).toString(),
                helpMessage: this.$te(c.helpMessageKey) ? this.$t(c.helpMessageKey).toString() : undefined,
                limited,
                icon: ConditionIcons[c.key]
              }
            })
          }
        })

      }

      return acc
    }, [])
  }

  sortByTab(tab: ConditionZone) {
    this.currentTab = tab
    this.filteredConditions = this.preparedConditions(this.currentTab)
  }

  created() {
    this.sortByTab(ConditionZone.Message)
  }
}
