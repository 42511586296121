





















































































































































import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import {
  ActionsType, ActionsWrapperType, ConditionsType, ConditionsWrapperType,
  TriggerActionSettingGroups, TriggerData
} from '@/components/TriggerSetup/logic/types/types'
import ConditionsGroups from '@/components/TriggerSetup/components/ConditionsGroups/ConditionsGroups.vue'
import ActionsGroups from '@/components/TriggerSetup/components/ActionsGroups/ActionsGroups.vue'
import TriggerAdvancedFormActionsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedFormActionsSettings/TriggerAdvancedFormActionsSettings.vue'
import TriggerAdvancedConditionSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedConditionSettings/TriggerAdvancedConditionSettings.vue'
import TriggerAdvancedUserLimitsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedUserLimitsSettings/TriggerAdvancedUserLimitsSettings.vue'
import TriggerAdvancedChatUserLimitsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedChatUserLimitsSettings/TriggerAdvancedChatUserLimitsSettings.vue'
import TriggerAdvancedWarnActionsSettings
  from '@/components/TriggerSetup/components/TriggerAdvancedWarnActionsSettings/TriggerAdvancedWarnActionsSettings.vue'
import { TriggerControlsMixin } from '@/components/TriggerSetup/logic/mixins/trigger-controls.mixin'
import checkChat from '@/assets/utils/checkChat'
import { InputSetups } from '@/mixins/input-setups'
import ActionsHelper, { actionGroupsTypes } from '@/components/TriggerSetup/actions'
import { TriggerHelper } from './logic/mixins/TriggerSetupMixin'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import ConditionHelper from '@/components/TriggerSetup/conditions/index'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import TriggerExtraSettings from '@/components/TriggerSetup/components/TriggerExtraSettings.vue'
import TriggerValidationMixin from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerValidationMixin'
import { ADVANCED_TAG, BRAND_TAG } from '@/includes/constants'
import { CheckLicenseKey } from "@/components/TriggerSetup/logic/di";
import { ActionIcons } from "@/components/TriggerSetup/actions/icons";

import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { TagData } from 'piramis-base-components/src/components/Tags/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue'
import { Entity } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";

import { Component, Mixins, VModel, Prop, Watch, Emit, Provide } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'

@Component({
  'components': {
    TriggerExtraSettings,
    TriggerAdvancedFormActionsSettings,
    ConditionsGroups,
    ActionsGroups,
    TriggerAdvancedConditionSettings,
    TriggerAdvancedUserLimitsSettings,
    TriggerAdvancedChatUserLimitsSettings,
    TriggerAdvancedWarnActionsSettings,
    NotAvailableOptionsOverlay,
    ConfigField,
    TextInput
  },
  data() {
    return {
      ListDirection,
      getTariffTranslationKey,
    }
  },
  'methods': {
    snakeCase
  },
  mixins: [ InputSetups ]
})
export default class TriggerSetup extends Mixins(TariffsTagsHelper, TriggerHelper, ActionsHelper, ConditionHelper, TriggerValidationMixin) {
  @VModel({ 'type': Object }) triggerData!: TriggerData

  @Prop({ 'required': true }) readonly controls!: { view: typeof TriggerControlsMixin, props: Record<string, any>, listeners: Record<string, any> }

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ type: Array, default: () => [], required: false }) blacklistedSettingGroups!: Array<TriggerActionSettingGroups>

  @Prop({ 'type': Boolean, required: false, default: true }) checkLicense!: boolean

  @Provide(CheckLicenseKey) checkLicenseProvide = this.checkLicense

  @Prop({ type: Array, default: () => [], required: false }) blacklistedActions!: Array<ActionsType | ActionsWrapperType>

  @Prop({ type: Array, default: () => [], required: false }) blacklistedConditions!: Array<ConditionsType | ConditionsWrapperType>

  @Emit()
  onValidationChange(val: boolean) {
    return val
  }

  @Watch('validTrigger', { immediate: true })
  onValidTriggerChange() {
    return this.onValidationChange(this.isTriggerValid(this.validTrigger))
  }

  get validTrigger(): Record<string, boolean> {
    return {
      trigger_validate_name: !!this.triggerData.name,
      trigger_validate_conditions: this.hasTriggerConditionGroups,
      trigger_validate_conditions_values: this.conditionValuesValid,
      trigger_validate_actions: this.trigger_validate_actions,
      trigger_validate_actions_values: this.trigger_validate_actions_values,
    }
  }

  get invalidTriggerFields():string | null {
    return this.firstInvalidTriggerField(this.validTrigger)
  }

  get hasTriggerConditionGroups():boolean {
    if (this.triggerData.conditions.length) {
      return this.triggerData.conditions.every(c => c.value.length > 0)
    } else {
      return true
    }
  }

  get conditionValuesValid():boolean {
    if (this.hasTriggerConditionGroups) {
      return !this.triggerData.conditions.some(c => c.value.some(i => {
        // return false
        if (Array.isArray(i.value.structure.value)) {
          return !i.value.structure.value.length
        } else {
          return true
        }
      }))
    }

    return false
  }

  get trigger_validate_actions():boolean {
    return this.hasActionGroups(this.triggerData.actions)
      || this.hasActionGroups(this.triggerData.limitActions)
      || this.hasActionGroups(this.triggerData.globalLimitActions)
  }

  get trigger_validate_actions_values():boolean {
    return this.actionValuesValid(this.triggerData.actions)
      || this.actionValuesValid(this.triggerData.limitActions)
      || this.actionValuesValid(this.triggerData.globalLimitActions)
  }

  get triggerType(): any {
    return checkChat(this.$route.path) ? 'chat' : 'global'
  }

  get filterActions() {
    return this.prepareActions('form')
  }

  get defaultChatActions() {
    return this.prepareActions('chat')
  }

  getTagStruct(optionKey: string): Array<TagData> | null {
    if ([
      'trigger_action_send_private_message_action',
      'trigger_action_change_points_interval_action',
      'trigger_action_set_points_action',
    ].includes(optionKey)) {
      return ADVANCED_TAG
    }

    return this.getTagsByFieldKey(optionKey)
  }

  showSettingGroup(group: TriggerActionSettingGroups) {
    if (this.blacklistedSettingGroups.length) {
      return !this.blacklistedSettingGroups.includes(group)
    } else {
      return true
    }
  }

  tariffTagIfPayed(type:any, optionKey:string) {
    return this.isAvailableTriggerFunction(type, optionKey) ? null : this.getTagStruct(optionKey)
  }

  prepareActions(filterType: 'chat' | 'form') {
    const groups = Object.entries(actionGroupsTypes)
      .filter(([ group ]) => filterType === 'form' ? group === 'formActions' : group !== 'formActions')
    const acc = []

    for (const [ group, keys ] of groups) {
      const groupItems = (keys as string[]).reduce((actions: Required<Pick<Entity, 'items'>>['items'], key) => {
        const action = this.actions?.[key]
        if (action) {

          let limited = null

          if (this.checkLicense) {
            if (typeof action.limited === 'function') {
              limited = action.limited()
            }
          }

          actions.push({
            key: action.key,
            title: this.$t(action.titleKey).toString(),
            helpMessage: this.$te(action.helpMessageKey) ? this.$t(action.helpMessageKey).toString() : undefined,
            limited,
            icon: ActionIcons[action.key]
          })
        }

        return actions
      }, [])

      acc.push({
        group: {
          guid: Guid.create().toString(),
          titleKey: `trigger_group_${ snakeCase(group) }`,
          groupItems
        }
      })
    }

    return acc

    // return Object.entries(actionGroupsTypes)
    //   // .filter(([ group ]) => filterType === 'form' ? group === 'formActions' : group !== 'formActions')
    //   .reduce((acc: Array<Entity>, [ group, keys ]) => {
    //     const groupItems = keys.reduce((actions: Required<Pick<Entity, 'items'>>['items'], key) => {
    //       const action = this.actions?.[key]
    //       if (action) {
    //
    //         let limited = null
    //
    //         if (this.checkLicense) {
    //           if (typeof action.limited === 'function') {
    //             limited = action.limited()
    //           }
    //         }
    //
    //         actions.push({
    //           key: action.key,
    //           title: this.$t(action.titleKey).toString(),
    //           helpMessage: this.$te(action.helpMessageKey) ? this.$t(action.helpMessageKey).toString() : undefined,
    //           limited,
    //           icon: ActionIcons[action.key]
    //         })
    //       }
    //
    //       return actions
    //     }, [])
    //
    //     acc.push({
    //       group: {
    //         guid: Guid.create().toString(),
    //         titleKey: `trigger_group_${ snakeCase(group) }`,
    //         groupItems
    //       }
    //     })
    //
    //     return acc
    //   })
  }

  getFilteredActions(filterType: 'chat' | 'form'): Array<EntityData> {
    const newActionsList: Array<EntityData> = []
    const brandTagIfChatNotBrand = () => this.$store.getters.getHaveLicense && this.$store.getters.getHaveBrand && this.$store.getters.isChatLicenseExists ? null : BRAND_TAG
    const DEPRECATED_ACTIONS = [ ActionsType.TriggerWarnAction ]

    // Object.values(this.actions)
    //   .filter(a => !this.blacklistedActions.includes(a.structure.type))
    //   .forEach((action: any) => {
    //     const actionType = action.structure.type
    //
    //     const actionOptionKey = `trigger_action_${ snakeCase(actionType) }`
    //
    //     if (filterType === 'form') {
    //       if (actionGroupsTypes['formActions'].includes(actionType)) {
    //         if (this.checkLicense) {
    //           if (actionType === ActionsType.SendFormResultToTarget) {
    //             action.limited = brandTagIfChatNotBrand()
    //           } else {
    //             action.limited = this.tariffTagIfPayed(actionType, actionOptionKey)
    //           }
    //         } else {
    //           action.limited = null
    //         }
    //
    //         newActionsList.push(action)
    //       }
    //     } else {
    //       if (!actionGroupsTypes['formActions'].includes(actionType) && !DEPRECATED_ACTIONS.includes(actionType)) {
    //         if (this.checkLicense) {
    //           if (actionType === ActionsType.SendMessageToTargetAction) {
    //             action.limited = brandTagIfChatNotBrand()
    //           } else {
    //             action.limited = this.tariffTagIfPayed(actionType, actionOptionKey)
    //           }
    //         } else {
    //           action.limited = null
    //         }
    //
    //         newActionsList.push(action)
    //       }
    //     }
    //   })

    return newActionsList
  }

}
